import { Injectable, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

import { Menu } from './menu.model';
import { verticalMenuItems } from './menu';
import { TranslateService } from '@ngx-translate/core';
import { I18nService } from '@app/core';
import { Subscription } from 'rxjs';
import { ChangeDetectorRef } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class MenuService implements OnInit {
  private langChangeSubscription: Subscription;
  public selectedLanguage: string;
  public selectedLanguageIcon: string;

  constructor(
    private location: Location,
    private router: Router,
    private i18nService: I18nService,
    private translateService: TranslateService
  ) {}

  // public getVerticalMenuItems(): Array<Menu> {
  //   return verticalMenuItems;
  // }

  ngOnInit(): void {
    this.i18nService.init('en', ['en', 'es', 'jp', 'fr', 'da', 'de', 'ru', 'tw', 'cn', 'kr']);
    this.selectedLanguage = this.i18nService.getCurrentLanguage();
    this.langChangeSubscription = this.i18nService.langChange$.subscribe((lang) => {
      this.refreshComponent(lang);
    });
  }

  private refreshComponent(lang: string): void {
    this.translateService.use('en');
    this.getTranslatedMenuItems();
  }

  public getVerticalMenuItems(): Array<Menu> {
    return this.getTranslatedMenuItems();
  }

  private getTranslatedMenuItems(): Array<Menu> {
    const menuItems = [
      new Menu(1, 'menu.dashboard', '/dashboard', null, 'dashboard', null, false, 0),
      new Menu(41, 'menu.video_tutorials', '/video-tutorials', null, 'help', null, false, 0),
      new Menu(2, 'menu.video_library', '/video-library', null, 'video_library', null, false, 0),
      new Menu(3, 'menu.audio_library', '/audio-library', null, 'audiotrack', null, false, 0),
      new Menu(5, 'menu.channels', '/channels', null, 'live_tv', null, false, 0),
      new Menu(7, 'menu.video_playlists', '/playlist', null, 'theaters', null, true, 0),
      new Menu(9, 'menu.manage_video_playlists', '/video-playlists/manage-playlists', null, 'theaters', null, false, 7),
      new Menu(
        10,
        'menu.video_playlist_queue',
        '/video-playlists/playlist-queue',
        null,
        'queue_play_next',
        null,
        false,
        7
      ),
      new Menu(
        45,
        'menu.manage_audio_playlists',
        '/audio-playlists/manage-playlists',
        null,
        'queue_music',
        null,
        false,
        44
      ),
      new Menu(
        46,
        'menu.audio_playlist_queue',
        '/audio-playlists/playlist-queue',
        null,
        'queue_play_next',
        null,
        false,
        44
      ),
      new Menu(12, 'menu.video_on_demand', '/video-on-demand', null, 'ondemand_video', null, false, 0),
      new Menu(19, 'menu.audio_on_demand', '/audio-on-demand', null, 'library_music', null, false, 0),
      new Menu(15, 'menu.broadcast_live', '/video-library', null, 'videocam', null, true, 0),
      new Menu(16, 'menu.start_broadcast', '/start-broadcast', null, 'play_circle_outline', null, false, 15),
      new Menu(17, 'menu.live_recorder', '/live-recorder', null, 'radio_button_checked', null, false, 15),
      new Menu(18, 'menu.restream_to_social', '/restreamer', null, 'facebook', null, false, 15),
      new Menu(45, 'menu.subscription', '/subscription', null, 'monetization_on', null, false, 0),
      new Menu(50, 'menu.ppv_events', '/ppv-events', null, 'event', null, false, 0),
      new Menu(30, 'menu.analytics', '/analytics', null, 'bar_chart', null, false, 0),
      new Menu(23, 'menu.push_notifications', '/video-library/1', null, 'smartphone', null, false, 21),
      new Menu(24, 'menu.embed_codes', '/video-library/1', null, 'code', null, false, 21),
      new Menu(
        11,
        'menu.video_playlist_checkup',
        '/video-playlists/playlist-checkup',
        null,
        'view_list',
        null,
        false,
        21
      ),
      new Menu(
        47,
        'menu.audio_playlist_checkup',
        '/audio-playlists/playlist-checkup',
        null,
        'view_list',
        null,
        false,
        21
      ),
      new Menu(26, 'menu.scheduling_history', '/video-library/1', null, 'history', null, false, 21),
      new Menu(27, 'menu.event_log', '/video-library/1', null, 'event', null, false, 21),
      new Menu(29, 'menu.supported_platforms', '/video-library/1', null, 'important_devices', null, false, 28),
      new Menu(51, 'menu.settings', '/app-setting', null, 'settings', null, false, 0),
      new Menu(52, 'menu.websites', '/websites', null, 'store', null, false, 53),
      new Menu(53, 'menu.website_builder', '/form-builder', null, 'view_compact', null, true, 0),
      new Menu(54, 'menu.template_gallery', '/templates', null, 'video_library', null, false, 53),
      new Menu(55, 'menu.form_builder', '/form-builder', null, 'view_compact', null, false, 53),
      // new Menu(56, 'menu.notifications', '/notifications', null, 'notifications_active', null, false, 0),
    ];

    // Translate menu items
    menuItems.forEach((item) => {
      this.translateService.get(item.title).subscribe((translation: string) => {
        item.title = translation;
      });
    });

    return menuItems;
  }

  public expandActiveSubMenu(menu: Array<Menu>) {
    let url = this.location.path();
    let routerLink = url; // url.substring(1, url.length);
    let activeMenuItem = menu.filter((item) => item.routerLink === routerLink);
    if (activeMenuItem[0]) {
      let menuItem = activeMenuItem[0];
      while (menuItem.parentId != 0) {
        let parentMenuItem = menu.filter((item) => item.id == menuItem.parentId)[0];
        menuItem = parentMenuItem;
        this.toggleMenuItem(menuItem.id);
      }
    }
  }

  public toggleMenuItem(menuId: any) {
    let menuItem = document.getElementById('menu-item-' + menuId);
    let subMenu = document.getElementById('sub-menu-' + menuId);
    if (subMenu) {
      if (subMenu.classList.contains('show')) {
        subMenu.classList.remove('show');
        menuItem.classList.remove('expanded');
      } else {
        subMenu.classList.add('show');
        menuItem.classList.add('expanded');
      }
    }
  }

  public closeOtherSubMenus(menu: Array<Menu>, menuId: any) {
    let currentMenuItem = menu.filter((item) => item.id == menuId)[0];
    if (currentMenuItem.parentId == 0 && !currentMenuItem.target) {
      menu.forEach((item) => {
        if (item.id != menuId) {
          let subMenu = document.getElementById('sub-menu-' + item.id);
          let menuItem = document.getElementById('menu-item-' + item.id);
          if (subMenu) {
            if (subMenu.classList.contains('show')) {
              subMenu.classList.remove('show');
              menuItem.classList.remove('expanded');
            }
          }
        }
      });
    }
  }
}
